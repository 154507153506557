import { HighlightScopes } from '@/constants';

/**
 * Highlighting feature where the user can select a color and paint results.
 * Used when generating reports and overrides any selected markup.
 * There are different selection modes (by domain, url, individually) and the most recently applied takes precedence.
 * 
 * type: refers to highlighting across cards - could be a type that has no URL, or a URL
 * 
 * @param {*} obj scope, color, resultId, typeId, domainId
 * @param {*} data 
 */
function updateHighlighting(obj, data) {
    if (!data.domainId) {
        data.domainId ||= 'nourl';

        if (data.scope === HighlightScopes.DOMAIN) {
            data.scope = HighlightScopes.URL;
        }
    }

    clearOverrides(obj, data);
    addHighlighted(obj, data);
    setStyles(buildStyles(obj));
}

function addHighlighted(obj, data) {
    let pointer;

    obj[data.domainId] ||= {};
    pointer = obj[data.domainId];

    if (data.scope !== HighlightScopes.DOMAIN) {
        pointer[data.typeId] ||= {};
        pointer = pointer[data.typeId];
    }

    if (data.scope === HighlightScopes.SINGLE) {
        pointer[data.resultId] = {};
        pointer = pointer[data.resultId];
    }

    pointer.color = data.color;
}

function clearOverrides(obj, data) {
    switch (data.scope) {
        case HighlightScopes.DOMAIN:
            delete obj[data.domainId];
            break;
        case HighlightScopes.URL:
            if (obj[data.domainId]) {
                delete obj[data.domainId][data.typeId];
            }
            break;
        case HighlightScopes.SINGLE:
            if (obj[data.domainId] && obj[data.domainId][data.typeId]) {
                delete obj[data.domainId][data.typeId][data.resultId];
            }
            break;
    }
}

const idsOnly = (key) => key !== 'color';
const resultSelector = (resultId, color) => buildRule(`[data-result-id="${resultId}"]`, color);
const typeSelector = (typeId, color) => buildRule(`[data-type-id="${typeId}"]`, color);
const domainSelector = (domainId, color) => buildRule(`[data-type-id*="${domainId}"]`, color);

const buildRule = (selector, color) => {
    const style = `background-color: ${color} !important`;
    return `${selector} { ${style} }`;
};

function buildStyles(obj) {
    const styles = [];
    const domainIds = Object.keys(obj);

    domainIds.forEach(domainId => {
        if (obj[domainId].color) {
            styles.push(domainSelector(domainId, obj[domainId].color));
        }
        const typeIds = Object.keys(obj[domainId]).filter(idsOnly);
        typeIds.forEach(typeId => {
            if (obj[domainId][typeId].color) {
                styles.push(typeSelector(typeId, obj[domainId][typeId].color));
            }
            const resultIds = Object.keys(obj[domainId][typeId]).filter(idsOnly);
            resultIds.forEach(resultId => {
                styles.push(resultSelector(resultId, obj[domainId][typeId][resultId].color));
            });
        });
    });

    return styles.join("\n");
}

function setStyles(styles, doc) {
    doc ||= document;
    let styleEl = doc.querySelector("#styles-highlighting");

    if (!styleEl) {
        styleEl = doc.createElement('style');
        styleEl.setAttribute('id', 'styles-highlighting');
        doc.querySelector('head').appendChild(styleEl);
    }
    styleEl.innerHTML = styles;
}

export default {
    setStyles,
    updateHighlighting,
};