import Punycode from 'punycode';

export default {
    stringToHash(string) {

        let hash = 0;
        string = (string || '').trim();
        if (!string || string.length == 0) {
            return hash;
        }
        for (let i = 0; i < string.length; i++) {
            let char = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = Math.abs(hash & hash);
        }

        return hash;
    },

    urlDecodeTrim(url) {

        let decode = ''
        try {
            decode = url ? decodeURIComponent(url.replace(/(^\w+:|^)\/\//, '').replace('www.', '')) : '';
        }
        catch (err) {
            console.debug('url is malformed :', url);
        }

        return decode;
    },

    urlConvertNonEnglishDomain(url) {
        if (!url) {
            return url;
        }

        let protocol = '', url_without_protocol = url;
        if (url.includes('//')) {
            // the converter doesn't handle urls, just strings
            protocol = url.substring(0, url.indexOf('//') + 2);
            url_without_protocol = url.substring(protocol.length);
        }

        return protocol + Punycode.toUnicode(url_without_protocol);
    },

    /**
     * Taken from the legacy implementation, which was adapted from the back end golang implementation
     * 
     * @param { string } url 
     * @returns 
     */
    extractDomain(url) {
        const self = this.extractDomain;
        self.re ||= {
            urlToDomain: /\/\/([^/()"' ?]+)(?:\/|$|\?)/,
            tld2PartDomains: /\.(?:org|net|com|gov|int|ch|de|fr|pt|ie|it|nl|be)$/,
            tld2Parts: /([^.]+\.[^.]+)$/,
            tld3Parts: /((?:[^.]+\.)?[^.]+\.[^.]+)$/,
        };

        if (url === 'google container' || url === '' || typeof url != 'string') {
            return null;
        }
        url = url.replace('//www.', '//');

        let match = url.match(self.re.urlToDomain);
        if (url.match(self.re.urlToDomain) === null) {
            url = '//' + url;
            match = url.match(self.re.urlToDomain);
            if (match === null) {
                return null;
            }
        }

        let domain = match[1];

        const tldRE = domain.match(self.re.tld2PartDomains) && !domain.endsWith('.go.com')
            ? self.re.tld2Parts
            : self.re.tld3Parts;

        match = domain.match(tldRE);
        if (match !== null) {
            domain = match[1];
        }

        return domain;
    },

    fixDescForDisplay(desc) {
        desc ||= "";
        return desc.indexOf('data:') === 0 ? "" : desc;
    },

    // Compares two luxon date objects
    datesMatch(a, b) {
        return a.ordinal == b.ordinal && a.year == b.year;
    },

    formattedRating(text, value) {
        let rating;
        if (value <= 200 || text.includes('/')) {
          rating = parseFloat((value / 100) * 5).toFixed(2);
        } else if (value > 200) {
          rating = parseFloat((value / 100) * 5).toFixed(0) + '%';
        }
        return rating;
      },
};