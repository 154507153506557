import appConfig from '@/../config/config.json';

let timer = null;
let cache = {};
const delay = 3000;
const keyPrefixBase = '_fb_';
const keyPrefix = keyPrefixBase + appConfig.app_type + '_';

function normalizeKey(key) {
    if (!key) {
        return '';
    }

    if (!key.startsWith(keyPrefixBase)) {
        key = keyPrefix + key;
    }

    return key;
}

function normalizeMapKey(key) {
    return key === undefined ? '' : key + '';
}

function exists(key) {
    key = normalizeKey(key);
    return localStorage.getItem(key) != null;
}

function get(key) {
    key = normalizeKey(key);
    const s = key in cache ? cache[key] : localStorage.getItem(key);
    return s || '';
}

/**
 * The cache and timer here is for efficiency - since we write to storage in watchers this prevents too much writing. 
 * Note: I didn't actually see a problem but read it that it could be and needed to move storage functions out for other reasons anyway.
 * 
 * @param {String} key 
 * @param {*} value 
 * @param {*} immediate if we can't wait for the delay to write - e.g., when leaving the page immediately after this call.
 */
function set(key, value, immediate = false) {
    key = normalizeKey(key);

    if (immediate) {
        localStorage.setItem(key, value);
        return;
    }

    cache[key] = value;
    clearTimeout(timer);
    timer = setTimeout(() => {
        Object.entries(cache).forEach(([key, value]) => localStorage.setItem(key, value));
        cache = {};
    }, delay);
}

function getMap(key) {
    try {
        const obj = JSON.parse(get(key));
        const keys = obj._ordered__keys_;
        const m = new Map();
        keys.forEach(key => m.set(key, obj[key]));
        return m;
    } catch (e) {
        return new Map();
    }
}

function setMap(key, m, immediate = false) {
    m.set('_ordered__keys_', Array.from(m.keys()));
    set(key, JSON.stringify(Object.fromEntries(m.entries())), immediate);
}

function getFromMap(key, mapKey) {
    return getMap(key).get(normalizeMapKey(mapKey));
}

function addToMap(key, mapKey, mapValue) {
    const max = 30;
    const m = getMap(key);
    mapKey = normalizeMapKey(mapKey);

    if (!(mapKey && mapValue)) {
        return;
    }

    if (m.has(mapKey)) {
        m.delete(mapKey); // so the map is sorted by the last time the key was used
    }

    m.set(mapKey, mapValue);

    if (m.size > max) {
        m.delete(m.keys().next().value); // delete first (oldest)
    }

    setMap(key, m);
}

export default {
    get,
    set,
    exists,
    getFromMap,
    addToMap,
    skipToDaysAdapter: (skip) => skip == -30 ? -30 : parseInt(skip) + 1,
    daysToSkipAdapter: (days) => days == -30 ? -30 : parseInt(days) - 1,
    legacyGetHighlightMarkup: () => get("highlight").replace('highlight-own-resembler', '').trim(),
};
