import cloneDeep from "lodash.clonedeep"
import commonUtil from "@/utils/commonUtil";

const separator = " ";
const labelPrefix = "highlight-";
const shortString = {
    none: "",
    owneddomain: "own-domain",
    ownedpage: "own-page",
    notowned: 'own-not-owned',
    resembler: "own-resembler",
    "-1": "rt-neg",
    "0": "rt-soso",
    "1": "rt-pos",
    "-4": "",
    unmarked: "",
    social: "ath-social",
    crowd: "ath-crowd",
    expert: "ath-expert",
    iauthority: "ath-iauth",
    wikipedia: "ath-wiki",
}

function markupToClasses(markup = []) {
    return Object.values(markup).map(value => shortString[value]).join(" ");
}

function markupSelectionsToLabels(classes) {
    return classes
        .flatMap((cl) => cl === "own-owned" ? ["own-domain", "own-page"] : cl)
        .map((cl) => labelPrefix + cl);
}

function labelsToMarkupClasses(labels) {
    const stored = (labels || "").split(separator);

    return stored
        .map((item) => {
            item = item.replace(labelPrefix, "");
            if (item == "own-domain") {
                item = "own-owned";
            }
            if (item == "own-page") {
                item = null;
            }
            return item;
        })
        .filter((item) => item);
}

function updateMarkupForResult(result, newMarkup, datetime) {
    result.markup = cloneDeep(newMarkup);

    if (result.markup.ratingHistory) {
        const cardTime = Date.parse(datetime) / 1000;
        const relevantRatingKey = Object.keys(result.markup.ratingHistory).filter(ts => +ts <= cardTime).sort().reverse()[0];
        result.markup.rating = result.markup.ratingHistory[relevantRatingKey];
    }
}

function updateTimelineMarkup(card, markupByUrlId) {
    const needsUpdate = (o) => Object.keys(markupByUrlId).includes(o.urlid + '');

    card.result.filter(result => result.computed.detailSection).forEach((result, index) => {
        const detailSection = result.computed.detailSection;
        if (result.computed.isContainer) {
            detailSection.filter(needsUpdate).forEach((o) => updateMarkupForResult(o, markupByUrlId[o.urlid], card.datetime));
        } else if (needsUpdate(detailSection)) {
            updateMarkupForResult(detailSection, markupByUrlId[detailSection.urlid], card.datetime);
            if (result.computed.hasSiteLinks) {
                card.result[index + 1].sitelink.forEach(sitelink => updateMarkupForResult(sitelink, markupByUrlId[detailSection.urlid], card.datetime));
            }
        }
    });
}

function getPossiblyAffectedUrlIds(cards, markupEditorData) {
    const domain = commonUtil.extractDomain(markupEditorData.url);
    const urlids = new Set();
    urlids.add(markupEditorData.urlid);

    cards.forEach(card => {
        card.result.filter(result => result.computed.detailSection).forEach(result => {
            if (result.computed.isContainer) {
                result.computed.detailSection.forEach((o) => addIfPossiblyAffected(o, o.resulttype));
            } else {
                addIfPossiblyAffected(result.computed.detailSection, result.resulttype)
            }
        });
    });

    function addIfPossiblyAffected(o, resultType) {
        if (isPossiblyAffected(o, resultType)) {
            urlids.add(o.urlid);
        }
    }

    /**
     * Adapted from legacy implementation.
     * There it handles youtube carousels but that's not needed so it was left out here.
     * I'm not sure why we don't look for same domain URLs in the listed container types.
     * 
     * @param { object } otherResult 
     * @param { string } changedResultType 
     * @returns 
     */
    function isPossiblyAffected(otherResult, changedResultType) {
        const ignoreTypes = ['videocontainervideo', 'newscontainernews', 'articlecontainernews'];
        if (!ignoreTypes.includes(changedResultType) && commonUtil.extractDomain(otherResult.url) === domain) {
            return true;
        }

        return false;
    }

    return Array.from(urlids);
}

export default {
    markupToClasses,
    markupSelectionsToLabels,
    labelsToMarkupClasses,
    getPossiblyAffectedUrlIds,
    updateTimelineMarkup,
};