<template>
    <result-wrapper :element-data="elementData"
                    flag-name="bi bi-link-45deg">
      <div class="markup-container">
          <span class="card-title ellipsis" v-html="elementData.minisitelinksbox.title"></span>
          <div class="ellipsis d-flex">
            <span class="me-1" v-if="'starrating' in elementData.minisitelinksbox">
              {{ formatRating }}
              <i class="bi bi-star-fill xs"></i>
            </span>
            <markup-link  :resultData=elementData.minisitelinksbox :datetime="elementData.minisitelinksbox.datetime"
                          :resultType="elementData.minisitelinksbox.resulttype"/>
          </div>
        </div>
    </result-wrapper>
  </template>
  
  <script>
  import MarkupLink from "@/components/card/MarkupLink.vue";
  import ResultWrapper from "@/components/card/ResultWrapper.vue";
  import commonUtil from "@/utils/commonUtil.js";
  
  export default {
    name: "MiniSiteLinksResult",
    props: {
      elementData: Object,
    },
    components: {
      MarkupLink,
      ResultWrapper,
    },
    data() {
      return {
      }
    },
    computed: {
    formatRating() {
      return commonUtil.formattedRating(this.elementData.minisitelinksbox.starrating.text, this.elementData.minisitelinksbox.starrating.value);
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "@/scss/_icons.scss";
  .xs {
    font-size: .75em;
    color: #FCD663;
    text-shadow:  0 0 2px #000;
}
  </style>
  