import { useRouter, useRoute } from 'vue-router';
import appConfig from '@/../config/config.json';

function pathOf(route) {
  return appConfig.routeBasePath + route;
}

function useRouteHelper() {

  const _route = useRoute();
  const _router = useRouter();

  function routeTo(route, query = _route.query) {
    const path = route ? pathOf(route) : _route.path;
    _router.push({ path, query });
  }

  function isRoute(route) {
    return pathOf(route) === _route.path;
  }

  return {
    routeTo,
    isRoute,
  };
}

export { useRouteHelper as default, pathOf };