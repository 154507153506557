<template>
  <container-wrapper v-slot="slotProps"
                     :element-key="elementKey"
                     :element-data="elementData"
                     :condensed="true"
                     :always-open="true"
                     :birdseye-always-open="true"
                     >
    
    <div v-for="(sitelink, index) in elementData.sitelink"
         class="accordion-item"
         :key="slotProps.accordionKey + index">
      <highlight-result :class="getMarkupClasses(sitelink)"
                      :element-data="sitelink">
        <div class="markup-container">
          <markup-link :resultData="getMarkupResultData(sitelink)" :datetime="elementData.datetime"
                       :resultType="elementData.resulttype">
            <span class="ellipsis title" v-html="sitelink.title" />
          </markup-link>
        </div>
      </highlight-result>
    </div>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "../ContainerWrapper/index.vue";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import { mapGetters } from "vuex";

export default {
  name: "sitelinkbox",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
  },
  methods: {
    getMarkupResultData(sitelink) {
      return Object.assign({}, sitelink, {
        displayUrl: sitelink.url,
        url: this.elementData.siteLinkParent.url,
        urlid: this.elementData.siteLinkParent.urlid,
      });
    },
  },
  computed: {
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/_icons.scss";

.title {
  margin-left: 0.5rem;
}
</style>
