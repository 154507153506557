import cloneDeep from "lodash.clonedeep"

const state = () => ({
    selectedIresult: {},
    iResultToolbarAction: {},
    iResultHistory: [],
    iResultRecommendations: [],
    iResultListStale: false,
    iResultNeedsUpdate: false,
    peerGroups: [],
    peerOptions: [],
    categoryTags: [],
    timelineResults: [],
    allTerms: []
})

const getters = {
    selectedIresult: state => state.selectedIresult,
    iResultToolbarAction: state => state.iResultToolbarAction,
    iResultHistory: state => state.iResultHistory,
    latestiResultHistory: state => {
        if (state.iResultHistory.length === 0) return {};
        const latest = state.iResultHistory[state.iResultHistory.length-1];
        return { ...latest, result: cloneDeep(latest.result)};
    },
    iResultRecommendations: state => state.iResultRecommendations,
    iResultListStale: state => state.iResultListStale,
    timelineResults: state => state.timelineResults,
    peerGroups: state => state.peerGroups,  
    categoryTags: state => state.categoryTags,  
    peerOptions: state => state.peerOptions,     
    allTerms: state => state.allTerms,
    iResultNeedsUpdate: state => state.iResultNeedsUpdate
}

const actions = {
    setSelectedIResult({ commit }, selectedIresult) {
        commit('SET_SELECTED_IRESULT', selectedIresult);
    },
    updateIResultCollection({ commit }, iresults) {
        commit('UPDATE_IRESULT_COLLECTION', iresults);
    },
    updateIResultId({ commit }, id) {
        commit('UPDATE_IRESULT_ID', id);
    },
    setIResultToolbarAction({ commit }, iResultToolbarAction) {
        commit('SET_IRESULT_TOOLBAR_ACTION', iResultToolbarAction);
    },
    addIresultToHistory({ commit }, selectedIresult) {
        commit('ADD_IRESULT_TO_HISTORY', selectedIresult);
    },
    removeIresultFromHistory({ commit }) {
        commit('REMOVE_IRESULT_FROM_HISTORY');
    },
    clearIresultHistory({ commit }) {
        commit('CLEAR_IRESULT_HISTORY');
    },
    setIResultRecommendations({ commit }, recommendations) {
        commit('SET_IRESULT_RECOMMENDATIONS', recommendations);
    },
    setIResultListStale({ commit }, iResultListStale) {
        commit('SET_IRESULT_LIST_STALE', iResultListStale);
    },    
    setTimelineResults({ commit }, timelineResults) {
        commit('SET_TIMELINE_RESULTS', timelineResults);
    },   
    setPeerGroups({ commit }, peerGroups) {
        commit('SET_PEER_GROUPS', peerGroups);
    },    
    setCategoryTags({ commit }, categoryTags) {
        commit('SET_CATEGORY_TAGS', categoryTags);
    },     
    setPeerOptions({ commit }, peerOptions) {
        commit('SET_PEER_OPTIONS', peerOptions);
    },        
    setAllTerms({ commit }, allTerms) {
        commit('SET_ALL_TERMS', allTerms);
    },   
    setIResultNeedsUpdate({ commit }, needsUpdate) {
        commit('SET_IRESULT_NEEDS_UPDATE', needsUpdate);
    },     
        
}    


const mutations = {
    SET_SELECTED_IRESULT(state, payload) {
        if (Object.keys(payload).length === 0) {
            state.selectedIresult = payload
        } else {
            state.selectedIresult = { ...state.selectedIresult, ...payload, result: cloneDeep(payload.result) };
        }
    },
    UPDATE_IRESULT_COLLECTION(state, payload) {
        state.selectedIresult = { ...state.selectedIresult, result: cloneDeep(payload)};
    },
    SET_IRESULT_TOOLBAR_ACTION(state, payload) {
        state.iResultToolbarAction = payload;
    },
    UPDATE_IRESULT_ID(state, payload) {
        state.selectedIresult.id = payload;
    },
    ADD_IRESULT_TO_HISTORY(state, payload) {
        state.iResultHistory.push(payload);
    },
    REMOVE_IRESULT_FROM_HISTORY(state) {
        state.iResultHistory.pop();
    },
    CLEAR_IRESULT_HISTORY(state) {
        state.iResultHistory = [];
    },
    SET_IRESULT_RECOMMENDATIONS(state, payload) {
        state.iResultRecommendations = payload;
    },
    SET_IRESULT_LIST_STALE(state, payload) {
        state.iResultListStale = payload;
    },
    SET_TIMELINE_RESULTS(state, payload) {
        state.timelineResults = payload;
    },    
    SET_PEER_GROUPS(state, payload) {
        state.peerGroups = payload;
    },     
    SET_PEER_OPTIONS(state, payload) {
        state.peerOptions = payload;
    },   
    SET_CATEGORY_TAGS(state, payload) {
        state.categoryTags = payload;
    },       
    SET_ALL_TERMS(state, payload) {
        state.allTerms = payload;
    },    
    SET_IRESULT_NEEDS_UPDATE(state, payload) {
        state.iResultNeedsUpdate = payload;
    },       

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
