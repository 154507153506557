<template>
  <container-wrapper v-slot="slotProps"
                     :element-key="elementKey"
                     :element-data="elementData"
                     display-name="Youtube carousel"
                     icon-name="icons video">


    <div v-for="(video, index) in elementData.video"
         class="accordion-item"
         :key="slotProps.accordionKey + index">
      <highlight-result :element-data="video"
                        :class="getMarkupClasses(video)">

        <div class="accordion-header markup-container markup-container-with-image"
             :id="'heading' + slotProps.accordionKey + index">
          <div class="outer-image-wrapper">
            <div class="image-wrapper">
              <img :src="video.desc || defaultNewsImage" />
            </div>
          </div>
          <div>
            <span class="ellipsis card-title" v-html="video.title" />
            <markup-link :resultData="video" :datetime="elementData.datetime"
                         :resultType="video.resulttype" />
          </div>
        </div>

      </highlight-result>
    </div>

  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/components/card/ContainerWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import { DEFAULT_NEWS_IMAGE } from "@/constants";

export default {
  name: "YoutubeCarousel",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
  },
  data() {
    return {
      defaultNewsImage: DEFAULT_NEWS_IMAGE,
    };
  },
};
</script>
