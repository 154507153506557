<template>
  <result-wrapper :element-data="elementData"
                  flag-name="faq">
    <div class="markup-container">
      <span class="card-title ellipsis" v-html="elementData.standard.title"></span>
      <div class="ellipsis d-flex">
        <span class="me-1" v-if="'starrating' in elementData.standard">
          {{ formatRating }}
          <i class="bi bi-star-fill xs"></i>
        </span>
        <markup-link :resultData="elementData.standard" :datetime="elementData.datetime"
                     :resultType="elementData.resulttype" />
      </div>
    </div>
  </result-wrapper>
</template>

<script>
import MarkupLink from "@/components/card/MarkupLink.vue";
import ResultWrapper from "@/components/card/ResultWrapper.vue";
import commonUtil from "@/utils/commonUtil.js";

export default {
  name: "Faq",
  props: {
    elementData: Object,
  },
  components: {
    MarkupLink,
    ResultWrapper,
  },
  data() {
    return {};
  },
  computed: {
    formatRating() {
      return commonUtil.formattedRating(this.elementData.standard.starrating.text, this.elementData.standard.starrating.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
.xs {
    font-size: .75em;
    color: #FCD663;
    text-shadow:  0 0 2px #000;
}
</style>

