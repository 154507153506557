<template>
  <result-wrapper :element-data="elementData"
                  :flag-name="isTwitter ? 'bi bi-twitter-x' : ''">
    <div class="markup-container">
      <div>
        <h5 class="card-title ellipsis" v-html="elementData.standard.title"></h5>

        <markup-link :resultData="elementData.standard" :datetime="elementData.datetime"
                     :resultType="elementData.resulttype">{{ formatUrl }}</markup-link>

        <div class="rating-box" v-if="'starrating' in elementData.standard">
          <div class="rating" :style='"width:" + elementData.standard.starrating.value + "%"'></div>
        </div>
      </div>
      <img v-if="elementData.standard.img" class="ms-3 rounded" :src=elementData.standard.img alt="">
    </div>
  </result-wrapper>
</template>

<script>
import MarkupLink from "@/components/card/MarkupLink.vue";
import ResultWrapper from "@/components/card/ResultWrapper.vue";

export default {
  name: "TwitterBox",
  props: {
    elementData: Object,
  },
  components: {
    MarkupLink,
    ResultWrapper,
  },
  data() {
    return {
    }
  },
  computed: {
    formatUrl() {
      const re = /(\?|&)ref_src.*/i;
      const url = this.elementData.standard.url;
      let fUrl = url ? url.replace(re, '') : '';
      return this.urlForDisplay(fUrl);
    },
    isTwitter() {
      return this.elementData.standard.url?.includes('twitter');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.medium-view {
  .fa-twitter {
    width: 0.65rem;
  }
}
</style>
