<template>
  <div ref="popup" v-if="isOn" class="dropdown-menu show" :style="getInlineStyle()" v-click-outside="off">
    <slot />
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "MiniPopup",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  // show - for data manipulation before render. shown - for access to the rendered popup
  emits: ["show", "hide", "shown"],
  data() {
    return {
      pad: 30,
      isOn: false,
    }
  },
  props: {
    // the tools menu moving back into the window makes the window flash with a scrollbar for a second. This avoids having to move the window automatically
    offsetX: Number,
  },
  methods: {
    off() {
      this.isOn = false;
      this.$emit("hide");
    },
    on() {
      this.isOn = true;
      window.setTimeout(() => {
        this.keepInWindow();
        this.$emit("shown");
      }, 0);
      this.$emit("show");
    },
    toggle() {
      this.isOn ? this.off() : this.on();
    },
    keepInWindow() {
      const el = this.$refs.popup;
      const bounds = el.getBoundingClientRect();

      const windowLeft = this.pad;
      const windowRight = window.innerWidth - this.pad;
      const windowTop = this.pad;
      const windowBottom = window.innerHeight - this.pad;

      const left = bounds.left;
      const right = bounds.right;
      const top = bounds.top;
      const bottom = bounds.bottom;

      const fixX = left < windowLeft ? windowLeft - left : right > windowRight ? windowRight - right : null;
      const fixY = top < windowTop ? windowTop - top : bottom > windowBottom ? windowBottom - bottom : null;

      if (fixX != null) {
        el.style.left = (el.offsetLeft + fixX) + 'px';
      }
      if (fixY != null) {
        el.style.top = (el.offsetTop + fixY) + 'px';
      }
    },
    getInlineStyle() {
      return this.offsetX ? { transform: "translateX(" + this.offsetX + "px)" } : {};
    },
    handleKeydown(e) {
      if (e.key === "Escape") {
        this.off();
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  unmounted() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  z-index: var(--z-popup);
  box-shadow: 0px 2px 7px rgba(100, 100, 100, 0.2);
  font-size: 14px;
}
</style>
