<template>
   <result-wrapper :element-data="elementData">
      <template v-if="imageData && zoomSelection !== 'small-view'">
         <img ref="thumbnail" :src="imageData" @click="openDialog()" />
         <template v-for="(item, idx) in elementData.featuredresultspanel" :key="idx">
            <div class="bounding-box" :style="getBoundingBoxStyle(item?.css?.BoundingBox)">
               <highlight-result :element-data="item" :class="getMarkupClasses(item)" @click="openDialog()" />
            </div>
         </template>
         <div v-if="showDialog" class="dialog-container">
            <FeaturedResultsDialog :header-data="headerData" :element-data="elementData" @hide="closeDialog()" />
         </div>
      </template>

      <div v-else class="markup-container">
         <div class="card-title ellipsis featured-results">Featured Results Panel</div>
      </div>
 

      <template #small-view>
         <small-view-result icon-name="featured-results" @click="openDialog()"/> 
         <div v-if="showDialog" class="dialog-container">
            <FeaturedResultsDialog :header-data="headerData" :element-data="elementData" @hide="closeDialog()" />
         </div>
      </template>

   </result-wrapper>
</template>
  


<script>
import ResultWrapper from "../ResultWrapper.vue";
import FeaturedResultsDialog from "../FeaturedResultsDialog.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import SmallViewResult from "@/components/card/SmallViewResult.vue";
import { mapGetters } from "vuex";

export default {
   name: "FeaturedResultsPanel",
   props: {
      elementKey: String,
      elementData: Object,
      headerData: Object,

   },
   data() {
      return {
         showDialog: false,
         renderedWidth: 0,  // Default value
         renderedHeight: 0,  // Default value     
      }
   },
   components: {
      ResultWrapper,
      FeaturedResultsDialog,
      HighlightResult,
      SmallViewResult
   },
   computed: {
      imageData() {
         const imageUrl = this.elementData?.featuredresultsthumbnail || null;
         if (imageUrl) {
            const cacheBuster = new Date().getTime(); // generate a unique cache buster
            return `${imageUrl}?t=${cacheBuster}`; // append the cache buster to the URL
         }
         return null;
      },
      ...mapGetters("toolbar", {
         zoomSelection: "zoomSelection",
      }),
   },
   methods: {
      updateRenderedDimensions() {
         if (this.$refs.thumbnail) {
               this.renderedWidth = this.$refs.thumbnail.offsetWidth;
               this.renderedHeight = this.$refs.thumbnail.offsetHeight;
            }   
      },

      setInitialRenderedDimensions() {
         if (this.$refs.thumbnail) {
            this.$refs.thumbnail.onload = this.updateRenderedDimensions;
         }
      },
      openDialog() {
         this.showDialog = true;
      },
      closeDialog() {
         this.showDialog = false;
      },
      getBoundingBoxStyle(box) {
         if (box) {
            // Original dimensions from elementData
            const originalWidth = this.elementData?.css?.BoundingBox?.Width || 1; // provide default to prevent division by zero
            const originalHeight = this.elementData?.css?.BoundingBox?.Height || 1; // provide default to prevent division by zero

            const x_adjustment = this.elementData?.css?.BoundingBox?.X ? this.elementData.css.BoundingBox.X : 0;
            const y_adjustment = this.elementData?.css?.BoundingBox?.Y ? this.elementData.css.BoundingBox.Y : 0;

            // Calculate scale factors using stored thumbnail dimensions
            const xScale = this.renderedWidth / originalWidth;
            const yScale = this.renderedHeight / originalHeight;

            // Adjust bounding box coordinates
            const adjustedX = (box.X - x_adjustment) * xScale;
            const adjustedY = (box.Y - y_adjustment) * yScale;
            const adjustedWidth = box.Width * xScale;
            const adjustedHeight = box.Height * yScale;

            return {
               top: `${adjustedY}px`,
               left: `${adjustedX}px`,
               width: `${adjustedWidth}px`,
               height: `${adjustedHeight}px`,
               position: "absolute",
               cursor: "pointer",  // Change the cursor to indicate it's clickable
            };
         }
      }
   },
   watch: {
      // Assuming that `elementData` is the reactive data that `getMarkupClasses` depends on
      elementData: {
         deep: true,
         handler() {
            this.$forceUpdate();
         }
      },
   },
   mounted() {
      this.$nextTick(() => {
         this.setInitialRenderedDimensions();
      });
   },
   updated() {
      this.$nextTick(() => {
      //   this.setInitialRenderedDimensions();
         this.updateRenderedDimensions();
      });
   },

};
</script>

<style lang="scss" scoped>
.image-container {
   position: relative;
}

.bounding-box {
   position: absolute;
   z-index: 10;
}

img {
   border-radius: var(--bs-border-radius);
   max-width: 82%;
   cursor: pointer;
   position: relative;
   z-index: 5;
}

.featured-results {
   font-size: 13px;
   font-weight: bold;
}

.card-with-markup-container {
   z-index: 100 !important;

   .image-container {
      position: relative;
      display: inline-block;
   }

   .bounding-box {
      .highlight-element {
         height: 100%;
         opacity: .5;
         border: 0;
         cursor: pointer;

         .markup-link-container {
            height: 100%;

            &.markup-link-text {
               color: transparent;
            }
         }
      }
   }
}
</style>