export class FeatureViewState {
	enabled = false;
	engine;
	
	constructor(engine) {
		this.engine = engine;
	}
	get enabled() {
		return this.enabled && this.engine.ctid;
	}
	set enabled(enabled) {
		this.enabled = enabled;
	}
	get ctid() {
		return this.engine.ctid;
	}
}
