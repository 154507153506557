import MarkupUtil from "@/utils/markupUtil";
import storage from "@/utils/storageUtil";
import api from '@/api';

const ratingOptions = [
    { name: "Positive", value: 'rt-pos' },
    { name: "Neutral", value: 'rt-soso' },
    { name: "Negative", value: 'rt-neg' }
];

const ownershipOptions = [
    { name: "Owned", value: 'own-owned' },
    { name: "Not Owned", value: 'own-not-owned' },
    { name: "Not Me", value: 'own-resembler' }
];

const authorityOptions = [
    { name: "iAuthority™", value: 'ath-iauth' },
    { name: "Expert", value: 'ath-expert' },
    { name: "Social", value: 'ath-social' },
    { name: "Wikipedia", value: 'ath-wiki' },
    { name: "Crowd", value: 'ath-crowd' },
];


// highlight is a calculated value from the other two
function getHighlightMarkupLabels(state) {
    if (state.highlightMarkup && state.markup) {
        const classes = [...state.rating, ...state.ownership, ...state.authority].map((o) => o.value);
        return MarkupUtil.markupSelectionsToLabels(classes);
    } else {
        return [];
    }
}

// setting highlight only for legacy compatibility.
function legacyStoreHighlight(state) {
    storage.set("highlight", getHighlightMarkupLabels(state).join(" "));
}

const state = () => ({
    markup: 'rating',
    highlightMarkup: true,
    markupDisabled: false,

    rating: ratingOptions,
    ownership: ownershipOptions,
    authority: authorityOptions,

    ratingOptions,
    ownershipOptions,
    authorityOptions,

    markupEditorData: null,
    markupResultData: null, // the entire object of the resulttype such as imagecontainer
});

const getters = {
    markup: state => state.markup,
    highlightMarkup: state => state.highlightMarkup,
    markupDisabled: state => state.markupDisabled,

    rating: state => state.rating,
    ownership: state => state.ownership,
    authority: state => state.authority,

    ratingOptions: state => state.ratingOptions,
    ownershipOptions: state => state.ownershipOptions,
    authorityOptions: state => state.authorityOptions,

    highlightMarkupLabels: state => getHighlightMarkupLabels(state),
    markupLabels: state => [state.markup, ...getHighlightMarkupLabels(state)],

    markupEditorData: state => state.markupEditorData,
    markupResultData: state => state.markupResultData,
};

const actions = {
    setMarkup({ commit }, markup) {
        commit('SET_MARKUP', markup);
    },
    setHighlightMarkup({ commit }, highlightMarkup) {
        commit('SET_HIGHLIGHT_MARKUP', highlightMarkup);
    },
    setMarkupDisabled({ commit }, payload) {
        commit('SET_MARKUP_DISABLED', payload);
    },

    setRating({ commit }, rating) {
        commit('SET_RATING', rating);
    },
    setOwnership({ commit }, ownership) {
        commit('SET_OWNERSHIP', ownership);
    },
    setAuthority({ commit }, authority) {
        commit('SET_AUTHORITY', authority);
    },

    setMarkupEditorData({ commit }, payload) {
        commit('SET_MARKUP_EDITOR_DATA', payload);
    },
    setMarkupResultData({ commit }, payload) {
        commit('SET_MARKUP_RESULT_DATA', payload);
    },

    updateMarkup({ commit, getters, rootGetters }, { markupType, newMarkup, ratingAction, possiblyAffectedUrlIds }) {
        const urlid = getters.markupEditorData.urlid || 0;
        const ts = getters.markupEditorData.ts || 0;
        const clientid = rootGetters['routeParams/client'].clientid;
        const start = rootGetters['routeParams/start'];
        const end = rootGetters['routeParams/end'];
        const ctid = rootGetters['routeParams/ctid'];
        const resultset = rootGetters['routeParams/resultset'];
        const resultType = getters.markupEditorData.resultType;

        const callApi = {
            rating: () => api.rateURL(clientid, newMarkup, urlid, ts, ratingAction, start, end, ctid, resultset, resultType),
            ownership: () => api.ownURL(clientid, newMarkup, urlid, possiblyAffectedUrlIds, ts, start, end, ctid, resultset, resultType),
            authority: () => api.setAuthority(clientid, newMarkup, urlid, start, end, ctid, resultset, resultType),
        }[markupType];

        return callApi().then(response => {
            commit('UPDATE_MARKUP_EDITOR_DATA', { markup: response.data[urlid] });
            return response.data;
        });
    },
};

const mutations = {
    SET_RATING(state, rating) {
        state.rating = rating;
        legacyStoreHighlight(state);
    },
    SET_OWNERSHIP(state, ownership) {
        state.ownership = ownership;
        legacyStoreHighlight(state);
    },
    SET_AUTHORITY(state, authority) {
        state.authority = authority;
        legacyStoreHighlight(state);
    },

    SET_MARKUP(state, markup) {
        state.markup = markup;
        storage.set("hlview", markup || "none");
        legacyStoreHighlight(state);
    },
    SET_HIGHLIGHT_MARKUP(state, highlightMarkup) {
        state.highlightMarkup = highlightMarkup;
        storage.set("highlightMarkup", highlightMarkup || "");
        legacyStoreHighlight(state);
    },
    SET_MARKUP_DISABLED(state, payload) {
        state.markupDisabled = payload;
    },

    SET_MARKUP_EDITOR_DATA(state, payload) {
        state.markupEditorData = payload;
    },
    SET_MARKUP_RESULT_DATA(state, payload) {
        state.markupResultData = payload;
    },
    UPDATE_MARKUP_EDITOR_DATA(state, payload) {
        Object.assign(state.markupEditorData, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
