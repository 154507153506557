import { EngineViewState } from "./EngineViewState.model";
import { FeatureViewState } from "./FeatureViewState.model";
import { ViewOptions } from "./ViewOptions.enum";

export class ViewStates {
    static engineOptions = [ViewOptions.GOOGLE, ViewOptions.BING, ViewOptions.BAIDU, ViewOptions.SO];
    static featureOptions = [ViewOptions.LOCATION, ViewOptions.DEVICE, ViewOptions.PEER, ViewOptions.ALL, ViewOptions.MOBILE_HISTORY];

    static isEngine = (option => option && ViewStates.engineOptions.map(o => o.key).includes(option.key));

    constructor() {
        ViewStates.engineOptions.forEach(option => this[option.key] = new EngineViewState());
        ViewStates.featureOptions.forEach(option => this[option.key] = new FeatureViewState(this[ViewOptions.GOOGLE.key]));
    }
}
