export const ViewOptions = Object.freeze({
  GOOGLE: { name: "Google History", key: "pc", id: "1" },
  BING: { name: "Bing History", key: "bingpc", id: "3" },
  BAIDU: { name: "Baidu History", key: "baidu", id: "4" },
  SO: { name: "SO 360 History", key: "so", id: "5" },

  DEVICE: { name: "Device", key: "device" },
  MOBILE_HISTORY: { name: "Mobile History", key: "mobile_history" },  
  PEER: { name: "Peers", key: "peergroup" },
  LOCATION: { name: "Locations", key: "geo" },
  ALL: { name: "All", key: "all" },

  valueOf,
});

const values = Object.values(ViewOptions).filter(value => value.key);

function valueOf(key) {
  for (let i = 0; i < values.length; i++) {
    if (values[i].key === key) {
      return values[i];
    }
  }
  return null;
}
