import { HighlightScopes } from '@/constants';
import HighlightUtil from '@/utils/highlightUtil';

const state = () => ({
    highlightMode: false,
    highlightColor: null,
    highlightScope: HighlightScopes.URL,
    highlighted: {},
    highlightedKp: {},
})

const getters = {
    highlightMode: (state) => state.highlightMode,
    highlightColor: (state) => state.highlightColor,
    highlightScope: (state) => state.highlightScope,
    highlighted: (state) => state.highlighted,
    highlightedKP: (state) => state.highlightedKp,
}

const actions = {
    toggleHighlightMode({ commit }) {
        commit('TOGGLE_HIGHLIGHT_MODE');
    },
    setHighlightColor({ commit }, payload) {
        commit('SET_HIGHLIGHT_COLOR', payload);
    },
    setHighlightScope({ commit }, payload) {
        commit('SET_HIGHLIGHT_SCOPE', payload);
    },
    addHighlighted({ commit }, { resultId, typeId, domainId }) {
        commit('ADD_HIGHLIGHTED', { resultId, typeId, domainId });
    },
    clearHighlighted({ commit }) {
        commit('CLEAR_HIGHLIGHTED');
    },
    addHighlightedKP({ commit }, payload) {
        commit('ADD_HIGHLIGHTED_KP', payload);
    },
    clearHighlightedKP({ commit }) {
        commit('CLEAR_HIGHLIGHTED_KP');
    },
}

const mutations = {
    TOGGLE_HIGHLIGHT_MODE(state) {
        state.highlightMode = !state.highlightMode
    },
    SET_HIGHLIGHT_COLOR(state, payload) {
        state.highlightColor = payload
    },
    SET_HIGHLIGHT_SCOPE(state, payload) {
        state.highlightScope = payload
    },
    ADD_HIGHLIGHTED(state, { resultId, typeId, domainId }) {
        HighlightUtil.updateHighlighting(state.highlighted, {
            scope: state.highlightScope,
            color: state.highlightColor,
            resultId,
            typeId,
            domainId,
        });
    },
    CLEAR_HIGHLIGHTED(state) {
        state.highlighted = {};
        HighlightUtil.setStyles('');
    },
    ADD_HIGHLIGHTED_KP(state, payload) {
        // payload: cardId, itemId
        state.highlightedKp[payload.cardId] = state.highlightedKp[payload.cardId] || {};
        state.highlightedKp[payload.cardId][payload.itemId] = state.highlightColor;
    },
    CLEAR_HIGHLIGHTED_KP(state) {
        state.highlightedKp = {};
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
