import { createApp } from 'vue';
import App from '@/App.vue';

import store from "@/store";
import router from '@/router';

import VueToast from 'vue-toast-notification';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import api from '@/api';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap";
import 'vue-diff/dist/index.css';

// common css definitions
import "@/scss/_bootstrap.scss";
import "@/scss/_colors.scss";
import "@/scss/_sizes.scss";
import "@/scss/_common.scss";

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// extend yaml language
import yaml from 'highlight.js/lib/languages/yaml';

// TODO remove these from main.js
import VueDiff from 'vue-diff';
import commonUtil from '@/utils/commonUtil';
import markupUtil from '@/utils/markupUtil';

const commonMixin = {
    methods: {
        stringToHash(string) {
            return commonUtil.stringToHash(string);
        },
        urlForDisplay(url) {
            return commonUtil.urlConvertNonEnglishDomain(commonUtil.urlDecodeTrim(url));
        },
        descForDisplay(desc) {
            return commonUtil.fixDescForDisplay(desc);
        },
        getMarkupClasses(element) {
            return element ? markupUtil.markupToClasses(element.markup) : '';
        },
    }
};

const { DateTime } = require("luxon");
const VueCookies = require('vue-cookies');

const app = createApp(App);
app.use(VueToast);
app.config.globalProperties.$dateTime = DateTime;
app.config.globalProperties.$api = api;
app.mixin(commonMixin);

app.use(PrimeVue);

app.use(VueCookies);
// Dispatch initializeStateFromCookies action when Vue app is created
if (store && store.dispatch) {
    store.dispatch('timeline/initializeStateFromCookies');
}

app.use(store);

router.beforeEach((to, from, next) => {
    app.config.globalProperties.$toast.clear();
    next();
});

app.use(router);

app.directive('tooltip', Tooltip);

app.mount('#app');

VueDiff.hljs.registerLanguage('yaml', yaml);
app.use(VueDiff, {
    componentName: 'VueDiff'
});

//TODO: move this notifier functionality out of main.js don't think it belongs here
function setToastNotification(o) {
    const duration = o.type == "error" ? 5000 : o.duration;
    if (o.show && !o?.on_dismiss) {
        app.config.globalProperties.$toast.open({
            message: o.message,
            duration,
            dismissible: duration > 2500,
            type: o.type || "info",
            onClick: o.click,

        });
    } else if (o.show && o?.on_dismiss) {
        app.config.globalProperties.$toast.open({
            message: o.message,
            dismissible: true,
            type: o.type || "info",
            duration: 0,
            onClick: o.click,
        });
    } else {
        app.config.globalProperties.$toast.clear();
    }
}

app.config.globalProperties.$notifier = {
    error: ({ message, e }) => {
        setToastNotification({ show: true, type: 'error', message });
        console.error(message, e);
    },
    info: ({ message, duration = 2500 }) => {
        setToastNotification({ show: true, message, duration });
    },
    hide: () => {
        setToastNotification({ show: false, message: "" });
    },
    setNotification :setToastNotification
}
