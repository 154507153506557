<template>
  <div class="small-view-result">
    <div v-if="iconName" class="icons" :class="iconName"></div>
  </div>
</template>

<script>
export default {
  name: "SmallViewResult",
  props: {
    iconName: String,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.small-view-result {
  height: var(--timeline-card-height-small);
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
