<template>
  <result-wrapper :element-data="elementData"
                  flag-name="bi bi-chat-left-text">
    <div class="markup-container">
      <div class="d-flex flex-column justify-content-center">
        <h5 class="card-title ellipsis" :title="elementData.standard.desc" v-html="elementData.standard.title"></h5>
        <markup-link :resultData="elementData.standard" :datetime="elementData.datetime"
                     :resultType="elementData.resulttype" />
      </div>
    </div>
  </result-wrapper>
</template>

<script>
import MarkupLink from "@/components/card/MarkupLink.vue";
import ResultWrapper from "@/components/card/ResultWrapper.vue";

export default {
  name: "Snippet",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    MarkupLink,
    ResultWrapper,
  },
  data() {
    return {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card {

  .card-body {
    .ellipsis {
      width: 14rem;
    }

    i {
      position: absolute;
      right: 4px;
      top: 8px;
    }
  }
}
</style>
