<template>
  <carousel-wrapper
    v-slot="slotProps"
    :element-key="elementKey"
    :element-data="elementData"
    display-name="Short Videos"
    icon-name="icons videoshorts">
    <div
      v-for="(shortVideoItem, index) in elementData.shortvideo"
      :key="slotProps.accordionKey + index"
      class="accordion-item"
    >
      <highlight-result :element-data="shortVideoItem" :class="getMarkupClasses(shortVideoItem)">
        <div
          class="accordion-header markup-container markup-container-with-image"
          :id="'heading' + slotProps.accordionKey + index"
        >
          <div class="outer-image-wrapper">
            <div class="image-wrapper">
              <img :src="shortVideoItem.desc" />
            </div>
          </div>
          <div>
            <span class="card-title video-title ellipsis" v-html="shortVideoItem.title" />
            <markup-link
              :resultData="shortVideoItem"
              :datetime="elementData.datetime"
              :resultType="shortVideoItem.resulttype"
            />
          </div>
        </div>
      </highlight-result>
    </div>
  </carousel-wrapper>
</template>

<script>
import CarouselWrapper from "@/components/card/CarouselWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";

export default {
  name: "ShortVideosContainer",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    CarouselWrapper,
    MarkupLink,
    HighlightResult,
  },
  data() {
    return {
    };
  },
};
</script>

<scss lang="scss" scoped>
  .outer-image-wrapper {
    overflow: hidden;
  }
</scss>
