const state = () => ({
    client: {},
    myClients: [],
    userInfo: {},
    start: '',
    end: '',
    resultset: 'history',
    ctid: '',
    view: '',
})

const getters = {
    client: state => state.client,
    myClients: state => state.myClients,
    userInfo: state => state.userInfo,
    start: state => state.start,
    end: state => state.end,
    resultset: state => state.resultset,
    ctid: state => state.ctid,
    view: state => state.view,
    isViewHistory: state => state.resultset.includes('history'),
}

const actions = {
    setClient({ commit, state }, client) {
        commit('SET_CLIENT', client);

        const myClients = state.myClients || [];
        const inList = myClients.findIndex((myClient) => myClient.clientname === client.clientname) >= 0;
        if (!inList) {
            commit('SET_MY_CLIENTS', [client, ...myClients]);
        }
    },
    setMyClients({ commit }, myClients) {
        commit('SET_MY_CLIENTS', myClients);
    },
    setUserInfo({ commit }, userInfo) {
        commit('SET_USER_INFO', userInfo);
    },
    setStart({ commit }, start) {
        commit('SET_START', start);
    },
    setEnd({ commit }, end) {
        commit('SET_END', end);
    },
    setResultset({ commit }, resultset) {
        commit('SET_RESULTSET', resultset);
    },
    setCtid({ commit }, payload) {
        commit('SET_CTID', payload);
    },
    setCtidAndResultset({ commit }, payload) {
        commit('SET_CTID_AND_RESULTSET', payload);
    },
    setView({ commit }, payload) {
        commit('SET_VIEW', payload);
    },
}

const mutations = {
    SET_CLIENT(state, payload) {
        state.client = payload;
    },
    SET_MY_CLIENTS(state, payload) {
        state.myClients = payload;
    },
    SET_USER_INFO(state, payload) {
        state.userInfo = payload;
    },
    SET_START(state, payload) {
        state.start = payload;
    },
    SET_END(state, payload) {
        state.end = payload;
    },
    SET_RESULTSET(state, payload) {
        state.resultset = payload;
    },
    SET_CTID(state, payload) {
        state.ctid = +payload;
    },
    SET_CTID_AND_RESULTSET(state, payload) {
        if (payload.ctid != null) {
            state.ctid = +payload.ctid;
        }
        if (payload.resultset) {
            state.resultset = payload.resultset;
        }
    },
    SET_VIEW(state, payload) {
        state.view = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
