import api from '@/api';
const VueCookies = require('vue-cookies');
const RESTORE_MOVE_MODE_DELAY = 30000;
const RESTORE_MANUALLY_STOP_PROPAGATION_DELAY = 0;
const { DateTime } = require("luxon");

const state = () => ({
    mongoView: false,
    cards: [],
    openedResults: {
        questionanswerbox: true,
        imagecontainer: true,
        videocontainer: true,
        articlecontainer: true,
        latestfrom: true,
        newscontainer: true,
        profilepanel: true,
        youtubecarousel: true
    },
    openedSubResults: {},
    focusedElement: null,
    screenshotDialogData: {},
    copyCardData: {},
    moveCardMode: false,
    moveCardModeTimer: null,
    selectedTimeRange: getDefaultTimeRange(),
    selectedDate: new Date(),
    timelineImageOptionsMode: false,
    timelineImageOptionsTitle: '',
    timelineImageOptionsSelection: 'logo',

    outlinedItems: [],
    stickyOutlinedItems: [],
    isOutlinePropagationManuallyStopped: false,

    // remove this after transition from legacy
    paaOnlyViewFromLegacy: false,
});

const getDefaultTimeRange = () => {
    const now = DateTime.now();
    return [now.minus({ days: 6 }).toJSDate(), now.toJSDate()];
};
const getters = {
    mongoView: state => state.mongoView,
    cards: state => state.cards,
    isResultOpen: state => resultId => state.openedResults[resultId],
    isSubResultsOpen: state => resultId => state.openedSubResults[resultId],
    focusedElement: state => state.focusedElement,
    screenshotDialogData: state => state.screenshotDialogData,
    copyCardData: state => state.copyCardData,
    moveCardMode: state => state.moveCardMode,
    selectedTimeRange: (state) => state.selectedTimeRange,
    selectedDate: (state) => state.selectedDate,
    timelineImageOptionsMode: state => state.timelineImageOptionsMode,
    timelineImageOptionsTitle: state => state.timelineImageOptionsTitle,
    timelineImageOptionsSelection: state => state.timelineImageOptionsSelection,
    outlinedItems: state => state.outlinedItems,
    stickyOutlinedItems: state => state.stickyOutlinedItems,
    isOutlinePropagationManuallyStopped: state => state.isOutlinePropagationManuallyStopped,
    paaOnlyViewFromLegacy: state => state.paaOnlyViewFromLegacy,
};

const actions = {
    initializeStateFromCookies({ commit }) {
        // Retrieve openedResults from cookies if available
        const openedResultsFromCookies = VueCookies.get('openedResults');

        if (openedResultsFromCookies) {
            commit('SET_OPENED_RESULTS', openedResultsFromCookies);
        }
    },
    setMongoView({ commit }, payload) {
        commit('SET_MONGO_VIEW', payload ? 'mongo' : false);
    },
    loadCards({ commit, state }, { clientid, ctid, start, end, resultset }) {

        return new Promise((resolve, reject) => {
            api.getTimelineJson(clientid, ctid, start, end, resultset, state.mongoView)
                .then((response) => {
                    commit('SET_CARDS', response.data);
                    resolve(state.cards);
                }).catch((error) => {
                    commit('SET_CARDS', []);
                    console.error(error);
                    reject(error);
                });
        });
    },

    clearCards({ commit }) {
        commit('SET_CARDS', []);
    },

    // Flexible actions that made it easier to transition to using vuex for timeline cards. 
    // Going forward either refactor to have specific actions here, or if this flexibility
    // is useful we can keep doing it this way.
    updateEachCard({ commit }, fn) {
        commit('UPDATE_EACH_CARD', fn);
    },
    updateCard({ commit }, { id, fn }) {
        commit('UPDATE_CARD', { id, fn });
    },

    // report features
    moveCard({ commit }, { targetIndex, sourceIndex }) {
        commit('MOVE_CARD', { targetIndex, sourceIndex });
    },
    insertCard({ commit }, { index, card }) {
        commit('INSERT_CARD', { index, card });
    },
    setMoveCardMode({ commit, dispatch }, on) {
        commit('SET_MOVE_CARD_MODE', on);
        if (on) {
            dispatch('resetMoveCardModeTimer');
        } else {
            dispatch('clearMoveCardModeTimer');
        }
    },
    resetMoveCardModeTimer({ commit, dispatch }) {
        dispatch('clearMoveCardModeTimer');
        commit('RESET_MOVE_CARD_MODE_TIMER', window.setTimeout(() => commit('SET_MOVE_CARD_MODE', false), RESTORE_MOVE_MODE_DELAY))
    },
    clearMoveCardModeTimer({ commit }) {
        commit('CLEAR_MOVE_CARD_MODE_TIMER');
    },

    toggleResultOpen({ commit, state }, resultId) {
        commit('TOGGLE_RESULT_OPEN', resultId);

        // Update cookie with the updated openedResults state
        VueCookies.set('openedResults', JSON.stringify(state.openedResults), '1y');
    },
    toggleSubResultsOpen({ commit, state }, resultId) {
        const open = !state.openedSubResults[resultId];
        commit('TOGGLE_SUB_RESULTS_OPEN', { resultId, open });
    },
    // Needed to load subresults as closed (instead of relying on default false when missing) so we know which ones to open when setting sub results open
    initClosedResults({ commit }, resultIds) {
        commit('INIT_CLOSED_RESULTS', resultIds);
    },
    setFocusedElement({ commit }, payload) {
        commit('SET_FOCUSED_ELEMENT', payload);
    },
    setScreenshotDialogData({ commit }, payload) {
        commit('SET_SCREENSHOT_DIALOG_DATA', payload);
    },
    setCopyCardData({ commit }, payload) {
        commit('SET_COPY_CARD_DATA', payload);
    },
    setSelectedTimeRange({ commit }, selectedTimeRange) {
        commit("SET_SELECTED_TIMERANGE", selectedTimeRange);
    },
    setSelectedDate({ commit }, selectedDate) {
        commit("SET_SELECTED_DATE", selectedDate);
    },
    toggleHideCardById({ commit }, id) {
        commit('UPDATE_CARD', { id, fn: card => card.hide = !card.hide });
    },
    toggleTimelineImageOptionsMode({ commit }) {
        commit('TOGGLE_TIMELINE_IMAGE_OPTIONS_MODE');
    },
    setTimelineImageOptionsTitle({ commit }, payload) {
        commit('SET_TIMELINE_IMAGE_OPTIONS_TITLE', payload);
    },
    setTimelineImageOptionsSelection({ commit }, payload) {
        commit('SET_TIMELINE_IMAGE_OPTIONS_SELECTION', payload);
    },
    addOutlinedItem({ commit }, payload) {
        commit('ADD_OUTLINED_ITEM', payload);
    },
    removeOutlinedItem({ commit }) {
        commit('SET_OUTLINED_ITEMS', []);
    },
    toggleStickyOutlinedItem({ commit }, payload) {
        commit('TOGGLE_STICKY_OUTLINED_ITEM', payload);
    },
    clearStickyOutlinedItems({ commit }) {
        commit('SET_STICKY_OUTLINED_ITEMS', []);
    },
    manuallyStopOutlinePropagation({ commit }) {
        commit('MANUALLY_STOP_OUTLINE_PROPAGATION', true);
        window.setTimeout(() => commit('MANUALLY_STOP_OUTLINE_PROPAGATION', false), RESTORE_MANUALLY_STOP_PROPAGATION_DELAY);
    },
    setPaaOnlyViewFromLegacy({ commit }, payload) {
        commit('SET_PAA_ONLY_VIEW_FROM_LEGACY', payload);
        commit('SET_FOCUSED_ELEMENT', payload ? 'questionanswerbox' : '');
    }
}

const mutations = {
    SET_MONGO_VIEW(state, payload) {
        state.mongoView = payload;
    },
    SET_CARDS(state, payload) {
        state.cards = payload;
    },
    UPDATE_EACH_CARD(state, fn) {
        state.cards.forEach(card => fn(card));
    },
    UPDATE_CARD(state, { id, fn }) {
        state.cards.forEach(card => {
            if (card.pollid === id) {
                fn(card);
            }
        });
    },
    MOVE_CARD(state, { sourceIndex, targetIndex }) {
        const card = state.cards.splice(sourceIndex, 1)[0];
        state.cards.splice(targetIndex, 0, card);
    },
    INSERT_CARD(state, { index, card }) {
        state.cards.splice(index, 0, card);
    },
    SET_OPENED_RESULTS(state, openedResults) {
        state.openedResults = openedResults;
    },
    TOGGLE_RESULT_OPEN(state, resultId) {
        state.openedResults[resultId] = !state.openedResults[resultId];
    },
    TOGGLE_SUB_RESULTS_OPEN(state, { resultId, open }) {
        state.openedSubResults[resultId] = !state.openedSubResults[resultId];
        Object.keys(state.openedResults).forEach(key => {
            if (key.includes(resultId) && key !== resultId) {
                state.openedResults[key] = open;
            }
        });
    },
    INIT_CLOSED_RESULTS(state, resultIds) {
        resultIds.forEach(resultId => state.openedResults[resultId] = false);
    },
    SET_FOCUSED_ELEMENT(state, payload) {
        state.focusedElement = payload;
    },
    SET_SCREENSHOT_DIALOG_DATA(state, payload) {
        state.screenshotDialogData = payload;
    },
    SET_COPY_CARD_DATA(state, payload) {
        state.copyCardData = payload;
    },
    SET_MOVE_CARD_MODE(state, on) {
        state.moveCardMode = on;
    },
    CLEAR_MOVE_CARD_MODE_TIMER(state) {
        if (state.moveCardModeTimer) {
            clearTimeout(state.moveCardModeTimer);
        }
    },
    RESET_MOVE_CARD_MODE_TIMER(state, timer) {
        state.moveCardModeTimer = timer;
    },
    TOGGLE_TIMELINE_IMAGE_OPTIONS_MODE(state) {
        state.timelineImageOptionsMode = !state.timelineImageOptionsMode;
    },
    SET_TIMELINE_IMAGE_OPTIONS_TITLE(state, payload) {
        state.timelineImageOptionsTitle = payload
    },
    SET_TIMELINE_IMAGE_OPTIONS_SELECTION(state, payload) {
        state.timelineImageOptionsSelection = payload
    },
    SET_PAA_ONLY_VIEW_FROM_LEGACY(state, payload) {
        state.paaOnlyViewFromLegacy = payload;
    },
    SET_SELECTED_TIMERANGE(state, payload) {
        state.selectedTimeRange = payload;
    },
    SET_SELECTED_DATE(state, payload) {
        state.selectedDate = payload;
    },

    ADD_OUTLINED_ITEM(state, payload) {
        state.outlinedItems.push(payload);
    },
    SET_OUTLINED_ITEMS(state, payload) {
        state.outlinedItems = payload;
    },
    TOGGLE_STICKY_OUTLINED_ITEM(state, { id, on }) {
        const index = state.stickyOutlinedItems.indexOf(id);
        const isOn = index >= 0;
        if (on === undefined) {
            on = !isOn;
        }

        if (on && !isOn) {
            state.stickyOutlinedItems.push(id);
        }

        if (!on && isOn) {
            state.stickyOutlinedItems.splice(index, 1);
        }
    },
    SET_STICKY_OUTLINED_ITEMS(state, payload) {
        state.stickyOutlinedItems = payload;
    },
    MANUALLY_STOP_OUTLINE_PROPAGATION(state, payload) {
        state.isOutlinePropagationManuallyStopped = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
