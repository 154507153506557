import countryDomains from '@/assets/json/country_domains.json';

export default function useLiveSearchHelper() {
    async function openSearch(term, location, country, language_code) {
        const query = encodeURIComponent(term);
        const hl = language_code;


        const countryCode = await getCountryCode(country);

        // Split the location into city and country code
        const lastSpaceIndex = location.lastIndexOf(' ');
        const city = location.substring(0, lastSpaceIndex);

        const uule = calculateUule(city, countryCode);
        const page = 1;
        const start = (page - 1) * 10;

        const cctld = await getGoogleDomain(countryCode);
        const myurl = `https://www.${cctld}/search?q=${query}&uule=${uule}&gl=${countryCode.toUpperCase()}&hl=${hl}&pws=0&start=${start}`;

        window.open(myurl, '_blank');
    }

    function calculateUule(selectedCity, selectedCountryCode) {
        if (selectedCountryCode.toUpperCase() === 'US') {
            selectedCity = selectedCity + ',United States';
        }
        const cityString = selectedCity.replace(/, /g, ',');
        const secretKeys = alphabetRange('A', 'Z').concat(
            alphabetRange('a', 'z'),
            numberRange(0, 9),
            ['-', '_']
        );
        const secretKey = secretKeys[cityString.length % secretKeys.length];
        const encodedCityString = btoa(encodeUtf8(cityString));
        return 'w+CAIQICI' + secretKey + encodedCityString;
    }

    async function getGoogleDomain(countryCode) {
        try {
            const countries = countryDomains;
            let domain = 'google.com';

            for (let i = 0; i < countries.length; i++) {
                if (countryCode.toLowerCase() === countries[i].country_code.toLowerCase()) {
                    domain = countries[i].domain;
                    break;
                }
            }

            return domain;
        } catch (error) {
            console.error('Error fetching country domains:', error);
            return 'google.com';
        }
    }

    async function getCountryCode(country) {
        try {
            const countries = countryDomains;
            let countryCode = '';

            for (let i = 0; i < countries.length; i++) {
                if (country?.toLowerCase() === countries[i]?.country.toLowerCase()) {
                    countryCode = countries[i].country_code;
                    break;
                }
            }

            return countryCode;
        } catch (error) {
            console.error('Error fetching country domains:', error);
            return 'US';
        }
    }

    function alphabetRange(start, end) {
        return new Array(end.charCodeAt(0) + 1 - start.charCodeAt(0)).fill().map((d, i) => {
            return String.fromCharCode(i + start.charCodeAt(0));
        });
    }

    function numberRange(start, end) {
        return new Array(end + 1 - start).fill().map((d, i) => {
            return i + start;
        });
    }

    function encodeUtf8(s) {
        return decodeURIComponent(encodeURIComponent(s));
    }

    return {
        openSearch
    };
}
