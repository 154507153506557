<template>
  <container-wrapper v-slot="slotProps"
                     :element-key="elementKey"
                     :element-data="elementData"
                     display-name="Profile panel"
                     icon-name="images">
    <template v-if="elementData?.profilepanel?.image">
      <img v-for="(image, index) in elementData.profilepanel?.image?.filter(item => validImage(item.url))"
           :key="slotProps.accordionKey + index"
           class="imagebox-item" :src="image.url" />
    </template>
  </container-wrapper>
</template>

<script>

/**
 * Profile panel is being treated like an Image container for now until we have requirements. Therefore, this is a copy of the imagecontainer.
 */

import ContainerWrapper from "@/components/card/ContainerWrapper";

export default {
  name: "ProfilePanel",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
  },
  methods: {
    validImage(url) {
      return url.length > 100 ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";

.card {
  img {
    height: 50px;
    margin: 0.5px;
    object-fit: cover;
    max-width: 19%;
    flex: 1;
    border-radius: 2px;
  }
}
</style>
