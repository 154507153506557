<template>
  <highlight-result class="card" :element-data="elementData">

    <div class="kp-wrapper" :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
         @click="setShowKpDialog(true)"
         :class="{ image: imageUrl, pointer: cardType !== views.PARSING_TESTER }">
      <span v-if="!imageUrl">KP</span>
    </div>
    <kp-dialog :elementData="elementData"
               :header-data="headerData"
               :show="showKpDialog"
               @hide="setShowKpDialog(false)" />

  </highlight-result>
</template>


<script>
import { _ } from "vue-underscore";
import { Views } from "@/constants";
import KpDialog from "@/components/card/KpDialog.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";

export default {
  name: "KnowledgePanel",
  props: {
    elementKey: String,
    headerData: Object,
    elementData: Object,
    cardType: String
  },
  data() {
    return {
      showKpDialog: false,
      views: Views,
    };
  },
  components: {
    KpDialog,
    HighlightResult,
  },
  computed: {
    imageUrl() {
      if ("kp" in this.elementData) {
          const kp = this.elementData.kp;
          if ("image" in kp) {
            if (Array.isArray(kp.image) && kp.image.length > 0) {
              return _.first(kp.image).url;
            }
          }
      }
      return "";
    },
  },
  methods: {
    setShowKpDialog(show) {
      if (this.cardType !== this.views.PARSING_TESTER) {
        this.showKpDialog = show;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: absolute;
  left: calc(var(--result-text-width-kp-adjust-regular) + var(--result-text-width-kp-offset-regular));
  border-radius: var(--border-radius-timeline-result);
  background-color: var(--timeline-page-bg);
  margin-left: -2px;

  .kp-wrapper {
    width: var(--timeline-result-height-regular);
    height: var(--timeline-result-height-regular);
    background-color: var(--fb-blue-70);
    border-radius: var(--border-radius-timeline-container);

    span {
      color: white;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }

    &.image {
      background-color: white;
      background-repeat: no-repeat;
      background-size: var(--timeline-result-height-regular) auto;
      background-position-x: center;
      background-position-y: center;
      border-radius: var(--border-radius-timeline-container);
    }
  }

  &.outlined .image {
    border-radius: var(--border-radius-timeline-result);
  }
}

.medium-view {
  .card {
    left: calc(var(--result-text-width-kp-adjust-medium) + var(--result-text-width-kp-offset-medium));

    .kp-wrapper {
      width: 30px;
      height: var(--timeline-result-height-medium);

      &.image {
        background-size: calc(var(--timeline-result-height-medium) - 2px) auto;
      }
    }
  }
}
</style>
