<template>
  <img v-if="url"
       :src="faviconUrl"
       class="favicon bi bi-globe-americas generic"
       :class="{ rounded }"
       :style="{ width: sizePx, height: sizePx, 'font-size': fontSizePx, position: position }" />
</template>
<script>
import commonUtil from "@/utils/commonUtil";
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: 16,
    },
    position: {
      type: String,
      required: false,
      default: 'static',
    }
  },
  data() {
    return {
      faviconPath: "https://web-images.fiveblocks.com/favicon",
    }
  },
  computed: {
    domain() {
      return commonUtil.extractDomain(this.url);
    },
    faviconUrl() {
      return [this.faviconPath, this.domain.slice(0, 2), this.domain].join("/") + "_favicon.ico";
    },
    sizePx() {
      return this.size + 'px';
    },
    fontSizePx() {
      return Math.floor(this.size * .9) + 'px';
    },
  },
}
</script>
<style lang="scss" scoped>
.favicon {
  max-width: 100%;
  max-height: 100%;
  display: block;

  &.bi:before {
    color: var(--disabled-text-color);
    vertical-align: .1rem;
  }

  &.rounded {
    border-radius: 40px;
    box-shadow: 0px 0px 3px var(--timeline-card-shadow-color);
    overflow: hidden;
  }
}
</style>