import { createStore, createLogger } from 'vuex';
import routeParams from '@/store/modules/routeParams.js';
import toolbar from '@/store/modules/toolbar';
import highlight from '@/store/modules/highlight';
import general from '@/store/modules/general';
import iresults from '@/store/modules/iresults.js';
import parsingTester from '@/store/modules/parsing-tester.js';
import timeline from '@/store/modules/timeline.js';
import markup from '@/store/modules/markup.js';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    routeParams,
    toolbar,
    iresults,
    highlight,
    general,
    parsingTester,
    timeline,
    markup,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})