<template>
  <Teleport to="body">
    <dialog-base :show="show"
                 @hide="$emit('hide')"
                 :is-draggable="true"
                 :close-on-click="true"
                 :styled="false"
                 size="lg">
      <template #content>
        <div class="card">
          <KP-card
                   v-if="term && term.html"
                   :key="term.id"
                   :is-hide="!!term.hide"
                   :term-data="term"
                   :header-data="getHeaderData(term)"
                   :should-zoom="false"
                   :is-links-active="true"
                   @showCard="show" />
        </div>
      </template>
    </dialog-base>
  </Teleport>
</template>

<script>
import { mapGetters } from "vuex";
import DialogBase from "@/components/modals/DialogBase.vue";
import KPCard from "@/components/KPCard.vue";
import { IMPACT_QUERY_DATE_FORMAT } from "@/constants";

export default {
  name: "KpDialog",
  emits: ["hide"],
  components: {
    DialogBase,
    KPCard,
  },
  props: {
    show: Boolean,
    headerData: Object,
    elementData: Object,
  },
  computed: {
    ...mapGetters("routeParams", {
      ctid: "ctid",
      resultset: "resultset",
      client: "client",
    }),
  },
  data() {
    return {
      key: Date.now() + "kp-dialog",
      eventMounted: false,
      term: {},
    };
  },
  methods: {
    getHeaderData(term) {
      return {
        id: term.id,
        datetime: term.date,
        location: term.term?.location,
        country: term.term?.country,
        lang: term.term?.lang,
        engine: term.term?.engine,
        device: term.term?.device,
        keyword: term.term?.term,
        tid: term.term?.tid,
      };
    },
    processKP() {
      const date = this.$dateTime
        .fromISO(this.headerData.datetime)
        .toFormat(IMPACT_QUERY_DATE_FORMAT);

      this.$api
        .getTermRange(this.client.clientid, this.ctid, date, date, this.resultset)
        .then((res) => {
          this.term = res.data.find((t) => t.term.tid === this.headerData.tid);

          if (this.term) {
            const hashId = this.stringToHash(this.term.date + this.term.term.tid);
            this.term.id = hashId;

            const termdate = this.$dateTime.fromISO(this.term.date).toMillis() / 1000;

            return this.$api.getKpHtml(
              this.term.term.clientid,
              this.term.term.tid,
              termdate,
              1,
              0,
              1
            );
          } else {
            throw new Error("Term not found.");
          }
        })
        .then((res) => {
          if (res) {
            this.term.html = res.data;
            this.term.hasKP = true;
          }
        })
        .catch((error) => {
          console.error("Error processing KP:", error);
        })
        .finally(() => {
          this.$emit("setShowKpDialog", true);
        });
    },
  },
  watch: {
    show(currentValue) {
      if (currentValue === true) {
        if (!this.term.html) {
          this.processKP();
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  border: 0;
  min-height: 716px;
}

:deep(.card-content) {
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>