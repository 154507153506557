<template>
  <highlight-result :element-data="elementData" :class="getMarkupClasses(elementData.computed?.detailSection)">

    <template v-if="zoomSelection === 'small-view'">
      <slot name="small-view">
        <markup-link :resultData="elementData.computed?.detailSection" :datetime="elementData.datetime"
                     :resultType="elementData.resulttype">
          <small-view-result :icon-name="iconName || flagName" />
        </markup-link>
      </slot>
    </template>

    <div v-else class="result-container" :class="{ 'has-flag': flagName }">
      <span v-if="iconName" class="icons" :class="iconName"></span>
      <i v-if="flagName" class="flag icons" :class="flagName"></i>
      <slot />
    </div>

  </highlight-result>
</template>


<script>
import { mapGetters } from "vuex";
import HighlightResult from "./HighlightResult.vue";
import SmallViewResult from "./SmallViewResult.vue";
import MarkupLink from "@/components/card/MarkupLink.vue";

export default {
  name: "ResultWrapper",
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    iconName: {
      type: String,
      required: false,
    },
    flagName: {
      type: String,
      required: false,
    },
  },
  components: {
    HighlightResult,
    SmallViewResult,
    MarkupLink
  },
  computed: {
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.regular-view,
.medium-view {
  border-radius: var(--border-radius-timeline-result);

  .result-container {
    display: flex;
    transition: var(--transition-zoom-selection);
  }

  .simple-result .result-container {
    align-items: center;
    padding-left: 0.3em;
  }

  *,
  :slotted(*) {

    &.card-title,
    .card-title {
      color: var(--fb-blue-70);
      font-weight: 500;
      margin: 0;
    }

    .ellipsis {
      @include ellipsis;
    }

    .card-body {
      padding: 0;
      border-radius: 0.25rem;
    }
  }

  .flag {
    position: absolute;
    top: 0;
    right: 3px;
  }

}


@mixin zoomViewSizes($type) {
  .#{$type}-view {
    .result-container {
      height: var(--timeline-result-height-#{$type});
    }

    .adjust-for-kp {
      width: calc(var(--result-text-width-kp-adjust-#{$type}) + var(--timeline-result-width-padding));

      :slotted(.ellipsis) {
        max-width: var(--result-text-width-kp-adjust-#{$type});
      }

      .has-flag :slotted(.markup-container .card-title) {
        max-width: calc(var(--result-text-width-kp-adjust-#{$type}) - var(--result-container-title-flag-width-#{$type}));
      }
    }

    .has-flag :slotted(.markup-container .card-title) {
      max-width: calc(var(--result-text-width-#{$type}) - var(--result-container-title-flag-width-#{$type}));
    }

    .flag.bi {
      font-size: var(--result-container-title-flag-width-#{$type});
    }

    *,
    :slotted(*) {
      &>* {
        font-size: var(--timeline-font-size-#{$type});
      }

      .ellipsis {
        max-width: var(--result-text-width-#{$type});
      }

    }


    @if $type =='medium' {
      .flag {
        width: var(--result-container-title-flag-width-#{$type});
        height: var(--result-container-title-flag-width-#{$type});
      }
    }
  }
}

@include zoomViewSizes("regular");
@include zoomViewSizes("medium");

.small-view {
  .card-body {
    padding: 0;
    border-radius: 0.125rem;
  }
}

.iresult {

  *,
  :slotted(*) {
    .ellipsis {
      max-width: var(--result-text-width-iresult);
    }
  }
}
</style>
