const state = () => ({
    id: '',
    reference: '',
    target: '',
    date: '',
    tid: '',
    termsetid: '',
    type: '',
    elementType: '',
    breadcrumb: [],
})

const getters = {
    id: state => state.id,
    reference: state => state.reference,
    target: state => state.target,
    date: state => state.date,
    tid: state => state.tid,
    termsetid: state => state.termsetid,
    type: state => state.type,
    elementType: state => state.elementType,
    breadcrumb: state => state.breadcrumb,
}

const actions = {
    setRunState({ commit }, payload) {
        commit('SET_RUN_STATE', payload);
    },
    setType({ commit }, payload) {
        commit('SET_TYPE', payload);
    },
    setElementType({ commit }, payload) {
        commit('SET_ELEMENT_TYPE', payload);
    },
    setBreadcrumb({ commit }, payload) {
        commit('SET_BREADCRUMB', payload);
    },
}

const mutations = {
    SET_RUN_STATE(state, payload) {
        if (payload.id) {
            state.id = payload.id;
        }
        if (payload.reference) {
            state.reference = payload.reference;
        }
        if (payload.target) {
            state.target = payload.target;
        }
        if (payload.date) {
            state.date = payload.date;
        }
        if (payload.type) {
            state.type = payload.type;
        }
        if (payload.elementType) {
            state.elementType = payload.elementType;
        }
        if (payload.tid) {
            state.tid = payload.tid;
        }
        if (payload.termsetid) {
            state.termsetid = payload.termsetid;
        }
    },
    SET_TYPE(state, payload) {
        state.type = payload;
    },
    SET_ELEMENT_TYPE(state, payload) {
        state.elementType = payload;
    },
    SET_BREADCRUMB(state, payload) {
        state.breadcrumb = [...payload];
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
