<template>
  <result-wrapper :element-data="elementData">
    <div class='inline-kp' :class="{'no-image-inline-kp': !imageUrl, 'inlinekp-with-image': imageUrl}">

      <div v-if="imageUrl" class="kp-image-wrapper" :style="{ backgroundImage: 'url(' + imageUrl + ')' }"
           :class="{ image: imageUrl, pointer: cardType !== views.PARSING_TESTER }">
      </div>

      <div class="card-title  m-auto d-flex flex-column">
        <span v-if="title" class="ellipsis" v-html="title" :title="title" :class="description !== '' ? 'small-title' : 'title'"></span>
        <span v-if="subTitle" class="ellipsis" v-html="subTitle" :title="subTitle" :class="description !== '' ? 'subtitle' : 'small-subtitle'"></span>
      </div>

      <kp-dialog :elementData="elementData"
                 :header-data="headerData"
                 :show="showKpDialog"
                 @hide="setShowKpDialog(false)" />
    </div>

  </result-wrapper>

</template>


<script>
import { _ } from "vue-underscore";
import { Views } from "@/constants";
import KpDialog from "@/components/card/KpDialog.vue";
import ResultWrapper from "@/components/card/ResultWrapper.vue";

export default {
  name: "InlineKnowledgePanel",
  props: {
    elementKey: String,
    headerData: Object,
    elementData: Object,
    cardType: String
  },
  data() {
    return {
      showKpDialog: false,
      views: Views,
    };
  },
  components: {
    KpDialog,
    ResultWrapper
  },
  computed: {
    title() {
      if (this.elementData && this.elementData.kp && this.elementData.kp.bullet) {
        const bullet = this.elementData.kp.bullet;
        const titleRecord = bullet.find(record => record.startsWith("Title:"));
        if (titleRecord) {
          return titleRecord.replace("Title:", "").trim();
        } 
      }
      return "";
    },
    subTitle() {
      if (this.elementData && this.elementData.kp && this.elementData.kp.bullet) {
        const bullet = this.elementData.kp.bullet;
        const subTitleRecord = bullet.find(record => record.startsWith("SubTitle:"));
        if (subTitleRecord) {
          return subTitleRecord.replace("SubTitle:", "").trim();
        }
      }
      return "";
    },
    imageUrl() {
      if ("kp" in this.elementData) {
          const kp = this.elementData.kp;
          if ("image" in kp) {
            if (Array.isArray(kp.image) && kp.image.length > 0) {
              return _.first(kp.image).url;
            }
          }
      }
      return "";
    },
    description() {
      if (this.elementData && this.elementData.kp && this.elementData.kp.bullet) {
        const bullet = this.elementData.kp.bullet;
        const description = bullet.find(record => record.startsWith("Description:"));
        if (description) {
          return description.replace("Description:", "").trim();
        } 
      }
      return "";
    },
  },
  methods: {
    setShowKpDialog(show) {
      if (this.cardType !== this.views.PARSING_TESTER) {
        this.showKpDialog = show;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: inherit;

  .card-title {
    max-width: 8rem;
    font-size: var(--timeline-font-size-regular);
    margin: auto 5px !important;
  }

  .inline-kp {
    background-color: var(--timeline-card-container-header-bg);
    width: 100%;
    display: flex;
  }

  .no-image-inline-kp {
    flex-direction: column;
    justify-content: center;
  }



 .title, .subtitle, .small-title, .small-subtitle {
    color: black;
  }

  .title, .subtitle {
    font-size: .7rem;
    font-weight: 900;
  }

  .small-title, .small-subtitle {
    font-size: .60rem;
  }

  .kp-image-wrapper {
    width: var(--timeline-result-height-regular);
    height: var(--timeline-result-height-regular);
    background-color: var(--fb-blue-70);
    border-radius: 0.25rem;
    border: solid 1px white;
    margin-left: auto;

    span {
      color: white;
      font-weight: 600;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }

    &.image {
      background-color: white;
      background-repeat: no-repeat;
      background-size: var(--timeline-result-height-regular) auto;
      background-position-x: center;
      background-position-y: center;
      border-radius: 7px;

      text-align: right;
      margin-left: 0;
      margin-right: 0;

    }
  }
}

.medium-view {
  .card {
    .card-title {
      max-width: 3.5rem;
      font-size: var(--timeline-font-size-medium);
    }

    .title {
    font-size: var(--timeline-font-size-medium);
    font-weight: 900;
  }

  .subtitle {
    font-size: .45rem;
  }

    .kp-image-wrapper {
      width: 30px;
      height: var(--timeline-result-height-medium);

      span {
        color: white;
        font-weight: 600;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
      }

      &.image {
        background-size: calc(var(--timeline-result-height-medium) - 2px) auto;
      }
    }
  }
}
</style>
