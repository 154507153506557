<template>
  <Teleport to="body">
    <dialog-base :show="isShow()"
                 @hide="hide()"
                 :close-on-click="true"
                 :show-x="false"
                 :is-draggable="true"
                 size="lg"
                 class="card-with-markup-container">
      <template #content>
        <div class="image-container" :class="markupLabels">
          <img :src="thumbnail" />
          <template v-for="(item, idx) in elementData.featuredresultspanel" :key="idx">
            <div class="bounding-box" :style="getBoundingBoxStyle(item?.css?.BoundingBox)">
              <highlight-result :element-data="item" :class="getMarkupClasses(item)">
                <markup-link :resultData="item" :datetime="elementData.datetime" :resultType="item.resulttype" />
              </highlight-result>
            </div>
          </template>
        </div>
        <div @click="hide()">
          <i class="bi bi-x-circle close-icon"></i>
        </div>
      </template>
    </dialog-base>
  </Teleport>
</template>


<script>
import { mapGetters } from "vuex";
import { IMPACT_QUERY_DATE_FORMAT } from "@/constants";
import DialogBase from "@/components/modals/DialogBase.vue";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";

export default {
  name: "FeaturedResultsDialog",
  components: {
    DialogBase,
    MarkupLink,
    HighlightResult
  },
  data() {
    return {
      thumbnail: "",
      timeoutID: null,
    };
  },
  props: {
    headerData: Object,
    elementData: Object,
  },
  computed: {
    ...mapGetters("markup", { markupLabels: "markupLabels" }),
  },
  methods: {
    async processFeaturedResult() {
      const date = this.$dateTime
        .fromISO(this.headerData.datetime)
        .toFormat(IMPACT_QUERY_DATE_FORMAT);
      const res = await this.$api.getFeaturedResultsScreenshot(this.headerData.tid, date);
      this.thumbnail = res?.data ? "data:image/png;base64," + res.data : "";
    },
    isShow() {
      return this.thumbnail !== "";
    },
    hide() {
      this.$emit("hide");
    },
    getBoundingBoxStyle(box) {
      if (box) {
        const x_adjustment = this.elementData?.css?.BoundingBox?.X ? this.elementData.css.BoundingBox.X : 0;
        const y_adjustment = this.elementData?.css?.BoundingBox?.Y ? this.elementData.css.BoundingBox.Y : 0;
        return {
          top: `${box.Y - y_adjustment}px`,
          left: `${box.X - x_adjustment}px`,
          width: `${box.Width}px`,
          height: `${box.Height}px`,
          position: "absolute",
          cursor: "pointer",  // Change the cursor to indicate it's clickable
        };
      }
    }
  },
  mounted() {
    this.processFeaturedResult();
  },
};
</script>

<style lang="scss">
@import "@/scss/_markup.scss";

.card-with-markup-container {
  z-index: 100 !important;

  .image-container {
    position: relative;
    display: inline-block;
  }

  .bounding-box {
    pointer-events: auto;
    cursor: pointer;

    .highlight-element {
      height: 100%;
      opacity: .8;

      &:hover {
        border-color: black;
      }

      .markup-link-container {
        height: 100%;

        &.markup-link-text {
          color: transparent;
        }
      }
    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 20px;
    color: #6c757d;
    z-index: 9999;
    pointer-events: auto;
  }

  .draggable-container {
    transform: scale(0.8);
    transform-origin: top left;
  }
}
</style>
